import React from "react"
import { Button, Layout } from 'antd'
import SiteLayout from "../components/SiteLayout";


//@ts-ignore 
import styles from './kjopsvilkar.module.scss';


export default ({ children }) => (
    <SiteLayout>
<div className={styles.smallcontainer}>
<h1>Reservasjons- <br/>vilkår</h1>

<h2>1. Reservasjon av Oss-Brikken</h2>
<p>Grunnet stor etterspørsel for Oss-Brikken får du anledning til å reservere en plass i køen for nye Oss-brikker som er bestilt. For å sikre alle kunder lik mulighet til å reservere, er det kun du som sluttkunde som kan reservere en plass i bestillingskøen gjennom vårt system. Du har derfor ikke anledning til å gjøre en reservasjon på vegne av andre utenfor din husstand. Det er kun tillat å foreta en (1) reservasjon per person. Du må være over 18 år og eier av et målepunkt for å foreta en reservasjon. Reservasjonen gjelder kun for bestilling av Oss-Brikken i det norske markedet for personlig bruk i Norge.</p>


<h2>2. Levering</h2>
<p>Forventet leveringstid er mars-april 2020, men du vil bli holdt oppdatert om leveringstid. Vi tar forbehold om at opplysningene om levering kan endres. Den faktiske leveringstiden på Oss-Brikken kan påvirkes av flere faktorer, for eksempel, men ikke begrenset til, tilgang på komponenter og materiale, produksjonstid og distribusjonstid.</p>

<h2>3. Beløp</h2>
<p>For at din reservasjon skal være gyldig må beløpet på kr 200,- være mottatt hos oss. Når betalingen av beløpet er gjennomført mottar du en skriftlig bekreftelse fra oss på din reservasjon via e-post med ditt ordrenummer. Ordrenummer er personlig og kan benyttes som identifikasjon ved henvendelser med oss. Vi kan kontaktes via hei@oss.no. Ved kansellering av reservasjonen vil du automatisk miste din plass i køen og det innbetalte beløpet vil tilbakebetales til din konto normalt innen fem virkedager.</p>

<h2>4. Pris</h2>
<p>Forespeilet pris for Oss-Brikken vil være kr 990,- inklusive mva. og med en månedskostnad på kr 39,- inklusive mva. Vi tar forbehold om at prisen kan endre seg frem til bestillingstidspunktet.</p>

<h2>5. Kjøpekontrakt</h2>
<p>Reservasjonen gir deg en prioritert plass i køen for bestilling av Oss-Brikker. Den utgjør ikke et kjøp, en endeling bestilling med leveransedato og garantert leveringsprioritet. Du vil bli kontakt når det åpnes for bestilling av Oss-Brikker, hvor du vil få tilbud om å inngå en kjøpekontrakt og foreta en endelig bestilling av Oss-Brikken. Når det åpnes for bestilling vil du ha en tidsbegrenset periode til å legge inn din bestilling. Når kjøpekontrakt er inngått vil du få det innbetalte beløpet trukket fra kjøpesum.</p>



</div>
    </SiteLayout>
)
